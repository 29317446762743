const columns = {
  zbl_data: [
    {
      name: "id",
      label: "id",
      inputType: "number",
    },
    {
      name: "client_platform",
      label: "client_platform",
      inputType: "text",
    },
    {
      name: "client_datetime",
      label: "client_datetime",
      inputType: "datetime-local",
      operators: [
        { name: "=", label: "=" },
        { name: "!=", label: "!=" },
        { name: "<", label: "<" },
        { name: ">", label: ">" },
        { name: "<=", label: "<=" },
        { name: ">=", label: ">=" },
      ],
    },
    {
      name: "client_locale_language",
      label: "client_locale_language",
      inputType: "text",
    },
    {
      name: "client_locale_country",
      label: "client_locale_country",
      inputType: "text",
    },
    { name: "client_app_name", label: "client_app_name", inputType: "text" },
    {
      name: "client_app_version",
      label: "client_app_version",
      inputType: "text",
    },
    { name: "client_app_patch", label: "client_app_patch", inputType: "text" },
    {
      name: "client_task_process",
      label: "client_task_process",
      inputType: "text",
    },
    { name: "client_task_step", label: "client_task_step", inputType: "text" },
    { name: "client_task_mode", label: "client_task_mode", inputType: "text" },
    { name: "user_id", label: "user_id", inputType: "text" },
    { name: "user_org_ids", label: "user_org_ids", inputType: "text" },
    { name: "dest_service", label: "dest_service", inputType: "text" },
    {
      name: "dest_service_action",
      label: "dest_service_action",
      inputType: "text",
    },
    {
      name: "dest_restrictions",
      label: "dest_restrictions",
      inputType: "text",
    },
    {
      name: "tracking_sessionid",
      label: "tracking_sessionid",
      inputType: "text",
    },
    {
      name: "tracking_requestid",
      label: "tracking_requestid",
      inputType: "text",
    },
    { name: "service_id", label: "service_id", inputType: "text" },
    { name: "service_version", label: "service_version", inputType: "text" },
    {
      name: "service_datetime",
      label: "service_datetime",
      inputType: "datetime-local",
      operators: [
        { name: "=", label: "=" },
        { name: "!=", label: "!=" },
        { name: "<", label: "<" },
        { name: ">", label: ">" },
        { name: "<=", label: "<=" },
        { name: ">=", label: ">=" },
      ],
    },
    {
      name: "service_duration",
      label: "service_duration",
      inputType: "number",
    },
    {
      name: "service_state_msg_code",
      label: "service_state_msg_code",
      inputType: "text",
    },
    {
      name: "service_state_msg",
      label: "service_state_msg",
      inputType: "text",
    },
    {
      name: "service_state_msg_source",
      label: "service_state_msg_source",
      inputType: "text",
    },
    { name: "service_state", label: "service_state", inputType: "text" },
    {
      name: "total_state_msg_code",
      label: "total_state_msg_code",
      inputType: "text",
    },
    { name: "total_state_msg", label: "total_state_msg", inputType: "text" },
    { name: "total_msg_source", label: "total_msg_source", inputType: "text" },
    { name: "total_state", label: "total_state", inputType: "text" },
    { name: "vehicle_vin", label: "vehicle_vin", inputType: "text" },
    { name: "vehicle_fin", label: "vehicle_fin", inputType: "text" },
    { name: "vehicle_category", label: "vehicle_category", inputType: "text" },
    {
      name: "vehicle_model_series",
      label: "vehicle_model_series",
      inputType: "text",
    },
    {
      name: "vehicle_model_type",
      label: "vehicle_model_type",
      inputType: "text",
    },
    { name: "creation_date", label: "creation_date",  inputType: "datetime-local",
    operators: [
      { name: "=", label: "=" },
      { name: "!=", label: "!=" },
      { name: "<", label: "<" },
      { name: ">", label: ">" },
      { name: "<=", label: "<=" },
      { name: ">=", label: ">=" },
    ], },
    { name: "zbl_version", label: "zbl_version", inputType: "text" },
    { name: "action_code", label: "action_code", inputType: "text" },
    { name: "routing_type", label: "routing_type", inputType: "text" },
    { name: "async_request_id", label: "async_request_id", inputType: "text" },
    { name: "async_redoc", label: "async_redoc", inputType: "text" },
    { name: "ain", label: "ain", inputType: "text" },
    { name: "aintype", label: "aintype", inputType: "text" },
    { name: "vehicle_status", label: "vehicle_status", inputType: "text" },
    { name: "lock_indicator", label: "lock_indicator", inputType: "text" },
    {
      name: "prd_liability_indic",
      label: "prd_liability_indic",
      inputType: "text",
    },
    { name: "pluscodes", label: "pluscodes", inputType: "text" },
    { name: "minuscodes", label: "minuscodes", inputType: "text" },
    { name: "code_list", label: "code_list", inputType: "text" },
    { name: "ttz", label: "ttz", inputType: "text" },
    { name: "tech_state", label: "tech_state", inputType: "text" },
    {
      name: "shipment_date",
      label: "shipment_date",
      inputType: "datetime-local",
      operators: [
        { name: "=", label: "=" },
        { name: "!=", label: "!=" },
        { name: "<", label: "<" },
        { name: ">", label: ">" },
        { name: "<=", label: "<=" },
        { name: ">=", label: ">=" },
      ],
    },
    {
      name: "tech_approv_date",
      label: "tech_approv_date",
      inputType: "datetime-local",
      operators: [
        { name: "=", label: "=" },
        { name: "!=", label: "!=" },
        { name: "<", label: "<" },
        { name: ">", label: ">" },
        { name: "<=", label: "<=" },
        { name: ">=", label: ">=" },
      ],
    },
    { name: "plant", label: "plant", inputType: "text" },
    { name: "vmax", label: "vmax", inputType: "text" },
    { name: "unit", label: "unit", inputType: "text" },
    { name: "caesar_num", label: "caesar_num", inputType: "text" },
    { name: "immo_action_code", label: "immo_action_code", inputType: "text" },
    { name: "immocode", label: "immocode", inputType: "text" },
    { name: "immo_identifiers", label: "immo_identifiers", inputType: "text" },
    { name: "data_mode", label: "data_mode", inputType: "text" },
    {
      name: "update_datetime",
      label: "update_datetime",
      inputType: "datetime-local",
      operators: [
        { name: "=", label: "=" },
        { name: "!=", label: "!=" },
        { name: "<", label: "<" },
        { name: ">", label: ">" },
        { name: "<=", label: "<=" },
        { name: ">=", label: ">=" },
      ],
    },
    { name: "diogenesname", label: "diogenesname", inputType: "text" },
    { name: "prod_data", label: "prod_data", inputType: "text" },
    { name: "hw_partnum", label: "hw_partnum", inputType: "text" },
    { name: "sw_partnums", label: "sw_partnums", inputType: "text" },
    { name: "idcode", label: "idcode", inputType: "text" },
    { name: "params_delta", label: "params_delta", inputType: "text" },
    { name: "sws_delta", label: "sws_delta", inputType: "text" },
    {
      name: "datarecords_delta",
      label: "datarecords_delta",
      inputType: "text",
    },
    { name: "datarecords", label: "datarecords", inputType: "text" },
    { name: "cbf_revision", label: "cbf_revision", inputType: "text" },
    { name: "diag_version", label: "diag_version", inputType: "text" },
    { name: "create_ecu", label: "create_ecu", inputType: "text" },
    { name: "ecu_params", label: "ecu_params", inputType: "text" },
    { name: "reshipping", label: "reshipping", inputType: "text" },
    { name: "x_calc_type", label: "x_calc_type", inputType: "text" },
    { name: "x_num_of_keys", label: "x_num_of_keys", inputType: "text" },
    {
      name: "x_transponder_code",
      label: "x_transponder_code",
      inputType: "text",
    },
    { name: "x_key_code", label: "x_key_code", inputType: "text" },
    { name: "calid_cvn", label: "calid_cvn", inputType: "text" },
    { name: "scn_vedoc", label: "scn_vedoc", inputType: "text" },
    { name: "scn_dialog", label: "scn_dialog", inputType: "text" },
    { name: "cvn", label: "cvn", inputType: "text" },
    { name: "refc", label: "refc", inputType: "text" },
    { name: "pcid", label: "pcid", inputType: "text" },
    { name: "serial_num", label: "serial_num", inputType: "text" },
    {
      name: "num_additional_comps",
      label: "num_additional_comps",
      inputType: "text",
    },
    { name: "navi_major_nums", label: "navi_major_nums", inputType: "text" },
    {
      name: "navi_estand_sw_id",
      label: "navi_estand_sw_id",
      inputType: "text",
    },
    { name: "scn_plant", label: "scn_plant", inputType: "text" },
    { name: "group_scn", label: "group_scn", inputType: "text" },
    { name: "is_cvn_mandatory", label: "is_cvn_mandatory", inputType: "text" },
    { name: "scn_partnum", label: "scn_partnum", inputType: "text" },
    { name: "scn_sw_nums", label: "scn_sw_nums", inputType: "text" },
  ],
  zkb_data: [
    {
      name: "id",
      label: "id",
      inputType: "number",
    },
    { name: "client_app_name", label: "client_app_name", inputType: "text" },
    { name: "client_app_patch", label: "client_app_patch", inputType: "text" },
    {
      name: "client_app_version",
      label: "client_app_version",
      inputType: "text",
    },
    {
      name: "client_datetime",
      label: "client_datetime",
      inputType: "datetime-local",
      operators: [
        { name: "=", label: "=" },
        { name: "!=", label: "!=" },
        { name: "<", label: "<" },
        { name: ">", label: ">" },
        { name: "<=", label: "<=" },
        { name: ">=", label: ">=" },
      ],
    },
    {
      name: "client_locale_country",
      label: "client_locale_country",
      inputType: "text",
    },
    {
      name: "client_locale_language",
      label: "client_locale_language",
      inputType: "text",
    },
    { name: "client_platform", label: "client_platform", inputType: "text" },
    { name: "client_task_mode", label: "client_task_mode", inputType: "text" },
    {
      name: "client_task_process",
      label: "client_task_process",
      inputType: "text",
    },
    { name: "client_task_step", label: "client_task_step", inputType: "text" },
    {
      name: "creation_date",
      label: "creation_date",
      inputType: "datetime-local",
      operators: [
        { name: "=", label: "=" },
        { name: "!=", label: "!=" },
        { name: "<", label: "<" },
        { name: ">", label: ">" },
        { name: "<=", label: "<=" },
        { name: ">=", label: ">=" },
      ],
    },
    {
      name: "dest_service_action",
      label: "dest_service_action",
      inputType: "text",
    },
    {
      name: "dest_restrictions",
      label: "dest_restrictions",
      inputType: "text",
    },
    { name: "dest_service", label: "dest_service", inputType: "text" },
    {
      name: "service_datetime",
      label: "service_datetime",
      inputType: "datetime-local",
      operators: [
        { name: "=", label: "=" },
        { name: "!=", label: "!=" },
        { name: "<", label: "<" },
        { name: ">", label: ">" },
        { name: "<=", label: "<=" },
        { name: ">=", label: ">=" },
      ],
    },
    { name: "service_duration", label: "service_duration", inputType: "text" },
    { name: "service_id", label: "service_id", inputType: "text" },
    { name: "service_state", label: "service_state", inputType: "text" },
    {
      name: "service_state_msg",
      label: "service_state_msg",
      inputType: "text",
    },
    {
      name: "service_state_msg_code",
      label: "service_state_msg_code",
      inputType: "text",
    },
    {
      name: "service_state_msg_source",
      label: "service_state_msg_source",
      inputType: "text",
    },
    { name: "service_version", label: "service_version", inputType: "text" },
    { name: "total_msg_source", label: "total_msg_source", inputType: "text" },
    { name: "total_state", label: "total_state", inputType: "text" },
    { name: "total_state_msg", label: "total_state_msg", inputType: "text" },
    {
      name: "total_state_msg_code",
      label: "total_state_msg_code",
      inputType: "text",
    },
    {
      name: "tracking_requestid",
      label: "tracking_requestid",
      inputType: "text",
    },
    {
      name: "tracking_sessionid",
      label: "tracking_sessionid",
      inputType: "text",
    },
    { name: "user_id", label: "user_id", inputType: "text" },
    { name: "user_org_ids", label: "user_org_ids", inputType: "text" },
    { name: "vehicle_category", label: "vehicle_category", inputType: "text" },
    { name: "vehicle_fin", label: "vehicle_fin", inputType: "text" },
    {
      name: "vehicle_model_series",
      label: "vehicle_model_series",
      inputType: "text",
    },
    {
      name: "vehicle_model_type",
      label: "vehicle_model_type",
      inputType: "text",
    },
    { name: "vehicle_vin", label: "vehicle_vin", inputType: "text" },
    {
      name: "calculation_result_exec_class",
      label: "calculation_result_exec_class",
      inputType: "text",
    },
    {
      name: "calculation_result_exec_duration",
      label: "calculation_result_exec_duration",
      inputType: "text",
    },
    {
      name: "calculation_result_exec_timestamp",
      label: "calculation_result_exec_timestamp",
      inputType: "datetime-local",
      operators: [
        { name: "=", label: "=" },
        { name: "!=", label: "!=" },
        { name: "<", label: "<" },
        { name: ">", label: ">" },
        { name: "<=", label: "<=" },
        { name: ">=", label: ">=" },
      ],
    },
    {
      name: "calculation_result_key_length",
      label: "calculation_result_key_length",
      inputType: "text",
    },
    {
      name: "calculation_result_server_name",
      label: "calculation_result_server_name",
      inputType: "text",
    },
    {
      name: "request_attr_algo_params_access_type",
      label: "request_attr_algo_params_access_type",
      inputType: "text",
    },
    {
      name: "request_attr_ecu_diogenes_name",
      label: "request_attr_ecu_diogenes_name",
      inputType: "text",
    },
    {
      name: "request_attr_ecu_variant",
      label: "request_attr_ecu_variant",
      inputType: "text",
    },
    {
      name: "request_attr_request_received_timestamp",
      label: "request_attr_request_received_timestamp",
      inputType: "datetime-local",
      operators: [
        { name: "=", label: "=" },
        { name: "!=", label: "!=" },
        { name: "<", label: "<" },
        { name: ">", label: ">" },
        { name: "<=", label: "<=" },
        { name: ">=", label: ">=" },
      ],
    },
    {
      name: "request_attr_algo_seed_length",
      label: "request_attr_algo_seed_length",
      inputType: "text",
    },
  ],
  vcs_data: [
    {
      name: "id",
      label: "id",
      inputType: "number",
    },
    { name: "client_app_name", label: "client_app_name", inputType: "text" },
    { name: "client_app_patch", label: "client_app_patch", inputType: "text" },
    {
      name: "client_app_version",
      label: "client_app_version",
      inputType: "text",
    },
    {
      name: "client_datetime",
      label: "client_datetime",
      inputType: "datetime-local",
      operators: [
        { name: "=", label: "=" },
        { name: "!=", label: "!=" },
        { name: "<", label: "<" },
        { name: ">", label: ">" },
        { name: "<=", label: "<=" },
        { name: ">=", label: ">=" },
      ],
    },
    {
      name: "client_locale_country",
      label: "client_locale_country",
      inputType: "text",
    },
    {
      name: "client_locale_language",
      label: "client_locale_language",
      inputType: "text",
    },
    { name: "client_platform", label: "client_platform", inputType: "text" },
    { name: "client_task_mode", label: "client_task_mode", inputType: "text" },
    {
      name: "client_task_process",
      label: "client_task_process",
      inputType: "text",
    },
    { name: "client_task_step", label: "client_task_step", inputType: "text" },
    {
      name: "creation_date",
      label: "creation_date",
      inputType: "datetime-local",
      operators: [
        { name: "=", label: "=" },
        { name: "!=", label: "!=" },
        { name: "<", label: "<" },
        { name: ">", label: ">" },
        { name: "<=", label: "<=" },
        { name: ">=", label: ">=" },
      ],
    },
    {
      name: "dest_service_action",
      label: "dest_service_action",
      inputType: "text",
    },
    {
      name: "dest_restrictions",
      label: "dest_restrictions",
      inputType: "text",
    },
    { name: "dest_service", label: "dest_service", inputType: "text" },
    {
      name: "service_datetime",
      label: "service_datetime",
      inputType: "datetime-local",
      operators: [
        { name: "=", label: "=" },
        { name: "!=", label: "!=" },
        { name: "<", label: "<" },
        { name: ">", label: ">" },
        { name: "<=", label: "<=" },
        { name: ">=", label: ">=" },
      ],
    },
    { name: "service_duration", label: "service_duration", inputType: "text" },
    { name: "service_id", label: "service_id", inputType: "text" },
    { name: "service_state", label: "service_state", inputType: "text" },
    {
      name: "service_state_msg",
      label: "service_state_msg",
      inputType: "text",
    },
    {
      name: "service_state_msg_code",
      label: "service_state_msg_code",
      inputType: "text",
    },
    {
      name: "service_state_msg_source",
      label: "service_state_msg_source",
      inputType: "text",
    },
    { name: "service_version", label: "service_version", inputType: "text" },
    { name: "total_msg_source", label: "total_msg_source", inputType: "text" },
    { name: "total_state", label: "total_state", inputType: "text" },
    { name: "total_state_msg", label: "total_state_msg", inputType: "text" },
    {
      name: "total_state_msg_code",
      label: "total_state_msg_code",
      inputType: "text",
    },
    {
      name: "tracking_requestid",
      label: "tracking_requestid",
      inputType: "text",
    },
    {
      name: "tracking_sessionid",
      label: "tracking_sessionid",
      inputType: "text",
    },
    { name: "user_id", label: "user_id", inputType: "text" },
    { name: "user_org_ids", label: "user_org_ids", inputType: "text" },
    { name: "vehicle_category", label: "vehicle_category", inputType: "text" },
    { name: "vehicle_fin", label: "vehicle_fin", inputType: "text" },
    {
      name: "vehicle_model_series",
      label: "vehicle_model_series",
      inputType: "text",
    },
    {
      name: "vehicle_model_type",
      label: "vehicle_model_type",
      inputType: "text",
    },
    { name: "vehicle_vin", label: "vehicle_vin", inputType: "text" },
    {
      name: "backend_key_identifier",
      label: "backend_key_identifier",
      inputType: "text",
    },
    { name: "code_list", label: "code_list", inputType: "text" },
    { name: "crin", label: "crin", inputType: "text" },
    { name: "diogenesname", label: "diogenesname", inputType: "text" },
    { name: "group_scn", label: "group_scn", inputType: "text" },
    { name: "hw_partnum", label: "hw_partnum", inputType: "text" },
    { name: "cvn_mandatory", label: "cvn_mandatory", inputType: "text" },
    {
      name: "notvalid_after",
      label: "notvalid_after",
      inputType: "datetime-local",
      operators: [
        { name: "=", label: "=" },
        { name: "!=", label: "!=" },
        { name: "<", label: "<" },
        { name: ">", label: ">" },
        { name: "<=", label: "<=" },
        { name: ">=", label: ">=" },
      ],
    },
    { name: "scn", label: "scn", inputType: "text" },
    { name: "scn_plant", label: "scn_plant", inputType: "text" },
    { name: "sw_partnum", label: "sw_partnum", inputType: "text" },
    {
      name: "subject_key_identifier",
      label: "subject_key_identifier",
      inputType: "text",
    },
    {
      name: "system_activation_import",
      label: "system_activation_import",
      inputType: "text",
    },
    { name: "ttz", label: "ttz", inputType: "text" },
    {
      name: "valid_from",
      label: "valid_from",
      inputType: "datetime-local",
      operators: [
        { name: "=", label: "=" },
        { name: "!=", label: "!=" },
        { name: "<", label: "<" },
        { name: ">", label: ">" },
        { name: "<=", label: "<=" },
        { name: ">=", label: ">=" },
      ],
    },
    { name: "api_version", label: "api_version", inputType: "text" },
  ],
  nissan_pin: [
    {
      name: "id",
      label: "id",
      inputType: "number",
    },
    { name: "client_app_name", label: "client_app_name", inputType: "text" },
    { name: "client_app_patch", label: "client_app_patch", inputType: "text" },
    {
      name: "client_app_version",
      label: "client_app_version",
      inputType: "text",
    },
    {
      name: "client_datetime",
      label: "client_datetime",
      inputType: "datetime-local",
      operators: [
        { name: "=", label: "=" },
        { name: "!=", label: "!=" },
        { name: "<", label: "<" },
        { name: ">", label: ">" },
        { name: "<=", label: "<=" },
        { name: ">=", label: ">=" },
      ],
    },
    {
      name: "client_locale_country",
      label: "client_locale_country",
      inputType: "text",
    },
    {
      name: "client_locale_language",
      label: "client_locale_language",
      inputType: "text",
    },
    { name: "client_platform", label: "client_platform", inputType: "text" },
    { name: "client_task_mode", label: "client_task_mode", inputType: "text" },
    {
      name: "client_task_process",
      label: "client_task_process",
      inputType: "text",
    },
    { name: "client_task_step", label: "client_task_step", inputType: "text" },
    {
      name: "creation_date",
      label: "creation_date",
      inputType: "datetime-local",
      operators: [
        { name: "=", label: "=" },
        { name: "!=", label: "!=" },
        { name: "<", label: "<" },
        { name: ">", label: ">" },
        { name: "<=", label: "<=" },
        { name: ">=", label: ">=" },
      ],
    },
    {
      name: "dest_service_action",
      label: "dest_service_action",
      inputType: "text",
    },
    { name: "dest_service", label: "dest_service", inputType: "text" },
    { name: "nissan_pin_pin", label: "nissan_pin_pin", inputType: "text" },
    { name: "nissan_pre_pin", label: "nissan_pre_pin", inputType: "text" },
    {
      name: "service_datetime",
      label: "service_datetime",
      inputType: "datetime-local",
      operators: [
        { name: "=", label: "=" },
        { name: "!=", label: "!=" },
        { name: "<", label: "<" },
        { name: ">", label: ">" },
        { name: "<=", label: "<=" },
        { name: ">=", label: ">=" },
      ],
    },
    { name: "service_duration", label: "service_duration", inputType: "text" },
    { name: "service_id", label: "service_id", inputType: "text" },
    { name: "service_state", label: "service_state", inputType: "text" },
    {
      name: "service_state_msg",
      label: "service_state_msg",
      inputType: "text",
    },
    {
      name: "service_state_msg_code",
      label: "service_state_msg_code",
      inputType: "text",
    },
    {
      name: "service_state_msg_source",
      label: "service_state_msg_source",
      inputType: "text",
    },
    { name: "service_version", label: "service_version", inputType: "text" },
    {
      name: "tracking_requestid",
      label: "tracking_requestid",
      inputType: "text",
    },
    {
      name: "tracking_sessionid",
      label: "tracking_sessionid",
      inputType: "text",
    },
    { name: "user_id", label: "user_id", inputType: "text" },
    { name: "user_org_ids", label: "user_org_ids", inputType: "text" },
    { name: "vehicle_category", label: "vehicle_category", inputType: "text" },
    { name: "vehicle_fin", label: "vehicle_fin", inputType: "text" },
    {
      name: "vehicle_model_series",
      label: "vehicle_model_series",
      inputType: "text",
    },
    {
      name: "vehicle_model_type",
      label: "vehicle_model_type",
      inputType: "text",
    },
    { name: "vehicle_vin", label: "vehicle_vin", inputType: "text" },
    { name: "nissan_vin", label: "nissan_vin", inputType: "text" },
    {
      name: "dest_restrictions",
      label: "dest_restrictions",
      inputType: "text",
    },
    { name: "client_ip", label: "client_ip", inputType: "text" },
  ],
  v_dwh_vpo_stat:[
    {name: "c_statisticsentry_id",label: "c_statisticsentry_id",inputType: "text",},
    {name: "c_vpo_stat_datum",label: "c_vpo_stat_datum",inputType: "datetime-local",
    operators: [
      { name: "=", label: "=" },
      { name: "!=", label: "!=" },
      { name: "<", label: "<" },
      { name: ">", label: ">" },
      { name: "<=", label: "<=" },
      { name: ">=", label: ">=" },
    ],
    },
    {name: "c_vpo_stat_br",label: "c_vpo_stat_br",inputType: "text",},
    {name: "c_vpo_stat_bm",label: "c_vpo_stat_bm",inputType: "text",},
    {name: "c_vpo_stat_vin",label:"c_vpo_stat_vin",inputType:"text",},
    {name: "c_vpo_stat_fin",label:"c_vpo_stat_fin",inputType:"text",},
    {name: "c_vpo_stat_sparte",label:"c_vpo_stat_sparte",inputType: "text",},
    {name: "c_vpo_stat_dvd",label:"c_vpo_stat_dvd",inputType:"text",},
    {name: "c_vpo_stat_msg",label:"c_vpo_stat_msg",inputType:"text",},
    {name: "c_vpo_stat_datapool",label: "c_vpo_stat_datapool",inputType: "text",},
    {name: "c_vpo_stat_flashdata",label:"c_vpo_stat_flashdata",inputType:"text",},
    {name: "c_vpo_stat_fw_mbs1",label:"c_vpo_stat_fw_mbs1",inputType:"text",},
    {name: "c_vpo_stat_server",label:"c_vpo_stat_server",inputType:"text",},
    {name: "c_vpo_stat_sessionid",label:"c_vpo_stat_sessionid",inputType:"text",},
    {name: "c_vpo_stat_sg_dasname",label:"c_vpo_stat_sg_dasname",inputType:"text",},
    {name: "c_vpo_stat_sg_diogname",label:"c_vpo_stat_sg_diogname",inputType:"text",},
    {name: "c_vpo_stat_sg_fittingflashsw",label:"c_vpo_stat_sg_fittingflashsw",inputType:"text",},
    {name: "c_vpo_stat_sg_mbs",label:"c_vpo_stat_sg_mbs",inputType:"text",},
    {name: "c_vpo_stat_msg",label:"c_vpo_stat_msg",inputType:"text",},
    {name: "c_vpo_stat_sg_newflashsw",label:"c_vpo_stat_sg_newflashsw",inputType:"text",},
    {name: "c_vpo_stat_sg_refnr",label:"c_vpo_stat_sg_refnr",inputType:"text",},
    {name: "c_vpo_stat_sourcesystem",label:"c_vpo_stat_sourcesystem",inputType:"text",},
    {name: "c_vpo_stat_symptomvp",label: "c_vpo_stat_symptomvp",inputType: "text",},
    {name: "c_vpo_stat_vp_duration",label:"c_vpo_stat_vp_duration",inputType:"text",},
    {name: "c_vpo_stat_vp_status",label:"c_vpo_stat_vp_status",inputType: "text",},
    {name: "c_vpo_stat_cpweb_info",label: "c_vpo_stat_cpweb_info",inputType: "text",},
    {name: "c_vpo_stat_vp_version",label:"c_vpo_stat_vp_version",inputType: "text",},
    {name: "c_vpo_stat_sessionid",label:"c_vpo_stat_sessionid",inputType:"text",},
    {name: "c_vpo_stat_businesstype",label: "c_vpo_stat_businesstype",inputType: "text",},
    {name: "c_vpo_stat_deviceid",label:"c_vpo_stat_deviceid",inputType:"text", },
    {name: "c_vpo_stat_deviceplatform",label:"c_vpo_stat_deviceplatform",inputType:"text",},
    {name: "c_vpo_stat_devicecomputername",label:"c_vpo_stat_devicecomputername",inputType:"text",},
    {name: "c_vpo_stat_applikation",label: "c_vpo_stat_applikation",inputType: "text",},
    {name: "c_vpo_stat_userid",label: "c_vpo_stat_userid",inputType: "text",},
    {name: "c_vpo_stat_server",label:"c_vpo_stat_server",inputType:"text",},
    {name: "c_vpo_stat_vp_methode",label:"c_vpo_stat_vp_methode",inputType: "text",},
    {name: "c_vpo_stat_ttz",label: "c_vpo_stat_ttz",inputType: "text",},
    {name: "c_vpo_stat_sg_dasname",label:"c_vpo_stat_sg_dasname",inputType:"text",},
    {name: "c_vpo_stat_sg_diogname",label:"c_vpo_stat_sg_diogname",inputType:"text",},
    {name: "c_vpo_stat_sg_mbs",label:"c_vpo_stat_sg_mbs",inputType:"text",},
    {name: "c_vpo_stat_sg_refnr",label:"c_vpo_stat_sg_refnr",inputType:"text",},
    {name: "c_vpo_stat_fw_mbs1",label:"c_vpo_stat_fw_mbs1",inputType:"text",},
    {name: "c_vpo_stat_sg_fittingflashsw",label:"c_vpo_stat_sg_fittingflashsw",inputType:"text",},
    {name: "c_vpo_stat_sg_newflashsw",label:"c_vpo_stat_sg_newflashsw",inputType:"text",},
    {name: "onlydate", label: "onlydate", inputType: "datetime-local",},
    { name: "onlydate",
    label: "onlydate",
    inputType: "datetime-local",
    operators: [
      { name: "=", label: "=" },
      { name: "!=", label: "!=" },
      { name: "<", label: "<" },
      { name: ">", label: ">" },
      { name: "<=", label: "<=" },
      { name: ">=", label: ">=" },
    ],
    },
    {name: "onlyhour",label:"onlyhour",inputType: "number",},
    { name: "onlytime",
    label: "onlytime",
    inputType: "time",
    operators: [
      { name: "=", label: "=" },
      { name: "!=", label: "!=" },
      { name: "<", label: "<" },
      { name: ">", label: ">" },
      { name: "<=", label: "<=" },
      { name: ">=", label: ">=" },
    ],
    },
  ],
  ascis:[
    {name: "client_sessionid",label: "client_sessionid",inputType: "text",},
    {name: "client_requestid",label: "client_requestid",inputType: "text",},
    {name: "client_requestcreationtime",label: "client_requestcreationtime",inputType: "text",},
    {name: "client_language",label:"client_language",inputType:"text",},
    {name: "client_country",label:"client_country",inputType:"text",},
    {name: "client_platform",label:"client_platform",inputType: "text",},
    {name: "client_appname",label:"client_appname",inputType:"text",},
    {name: "client_appversion",label:"client_appversion",inputType:"text",},
    {name: "client_taskprocess",label: "client_taskprocess",inputType: "text",},
    {name: "client_userid",label:"client_userid",inputType:"text",},
    {name: "destination_service",label:"destination_service",inputType:"text",},
    {name: "destination_action",label:"destination_action",inputType:"text",},
    {name: "vin",label:"vin",inputType:"text",},
    {name: "csr_ecu_public_key",label:"csr_ecu_public_key",inputType:"text",},
    {name: "ski",label:"ski",inputType:"text",},
    {name: "csr_supplier_signature_data",label:"csr_supplier_signature_data",inputType:"text",},
    {name: "responsecreationtime",label:"responsecreationtime",inputType:"text",},
    {name: "ecucertificate",label:"ecucertificate",inputType:"text",},
    {name: "backendcertificate",label:"backendcertificate",inputType:"text",},
    {name: "intermediatecertificate",label:"intermediatecertificate",inputType:"text",},
    {name: "errorcode",label:"errorcode",inputType:"text",},
    {name: "reason",label: "reason",inputType: "text",},
  ],
  csb_gsb_codierstringbuilder_request:[
    {name: "id",label: "id",inputType: "text"},
    {name: "sender",label: "sender",inputType: "text"},
    {name: "requestid",label: "requestid",inputType: "text"},
    {name: "sessionid",label: "sessionid",inputType: "text"},
    {name: "eventid",label: "eventid",inputType: "text"},
    {name: "fin",label: "fin",inputType: "text"},
    {name: "sourceapplicationname",label: "sourceapplicationname",inputType: "text"},
    {
      name: "dateoftechnicalstate",
      label: "dateoftechnicalstate",
      inputType: "datetime-local",
      operators: [
        { name: "=", label: "=" },
        { name: "!=", label: "!=" },
        { name: "<", label: "<" },
        { name: ">", label: ">" },
        { name: "<=", label: "<=" },
        { name: ">=", label: ">=" },
      ],
    },
    {name: "docreatescn",label: "docreatescn",inputType: "text"},
    {name: "doretrieveqnt",label: "doretrieveqnt",inputType: "text"},
    {name: "doretrievecv",label: "doretrievecv",inputType: "text"},
    {name: "doretrievecsnr",label: "doretrievecsnr",inputType: "text"},
    {name: "ecuname",label: "ecuname",inputType: "text"},
    {name: "hwsnr",label: "hwsnr",inputType: "text"},
    {name: "plantidentifier",label: "plantidentifier",inputType: "text"},
   ],

};

columns["zbl_data"].map(
  (column) =>
    (column["validator"] = (data) => ({
      valid: !!data.value.length,
      reasons: ["required"],
    }))
);
columns["zkb_data"].map(
  (column) =>
    (column["validator"] = (data) => ({
      valid: !!data.value.length,
      reasons: ["required"],
    }))
);
columns["vcs_data"].map(
  (column) =>
    (column["validator"] = (data) => ({
      valid: !!data.value.length,
      reasons: ["required"],
    }))
);
columns["nissan_pin"].map(
  (column) =>
    (column["validator"] = (data) => ({
      valid: !!data.value.length,
      reasons: ["required"],
    }))
);
columns["v_dwh_vpo_stat"].map(
  (column) =>
    (column["validator"] = (data) => ({
      valid: !!data.value.length,
      reasons: ["required"],
    })) 
);
columns["ascis"].map(
  (column) =>
    (column["validator"] = (data) => ({
      valid: !!data.value.length,
      reasons: ["required"],
    })) 
);
columns["csb_gsb_codierstringbuilder_request"].map(
  (column) =>
    (column["validator"] = (data) => ({
      valid: !!data.value.length,
      reasons: ["required"],
    })) 
);

export default columns;
